// eslint-disable-next-line import/prefer-default-export
export const retailLpHero = {
  title: "Sell in-store, online, and on the go",
  subtext:
    "Save time and boost sales with SpotOn Retail, the all-in-one point-of-sale solution designed to let you do it all.",
  heroImg: "retail-lp-hero.jpg",
  imageClassName: "smaller-hero",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/lp/retail-a/demo",
  },
  fatTitle: "",
};

export const retailLpTrusted = [
  {
    title: "70%",
    subtext: "of consumers prefer shopping local",
  },
  {
    title: "77%",
    subtext: "of consumers have ordered online for in-store pickup",
  },
  {
    title: "74%",
    subtext: "of people choose a store based on their loyalty reward program\n",
  },
];

export const retailLpWhiteGlove = {
  mainTitle: "A partner you can trust",
  title: "Custom setup & personalized support",
  content:
    "You deserve the same level of service that big box-retailers expect. Our team will work closely with you to build your cloud-based catalog, configure a slick point-of-sale device for in-store transactions, and even set up your online store for you—helping you save time and money while also reaching more customers. And we’ll continue to be there whenever you need us with a local, dedicated representative and 24/7 personalized support.",
  ctaInfoPrimary: {
    ctaTitle: "Learn more",
    ctaTarget: "/lp/retail-a/demo",
  },
};

export const retailLpLargeFeatures = {
  sectionTitle: "Better tech to reach your customers wherever they are",
  featureBlocks: [
    {
      blockTitle: "One catalog. One reporting system. One transparent price",
      blockList: [
        "Manage your products, services, and stock from one place",
        "Easily publish to your in-store POS devices and ecommerce store",
        "Sync sales across all channels to one reporting center",
        "Do it all from one online dashboard—anytime, anywhere",
        "Built-in payment processing with transparent, fair pricing",
      ],
      blockImg: "retail-lp-a.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/lp/retail-a/demo",
      },
    },
    {
      blockTitle:
        "Sell nationwide and compete with the big-box online retailers",
      blockImg: "retail-lp-b.png",
      blockList: [
        "Fast & easy shopping experience for your customers",
        "Offer same day delivery with our affordable, flat-fee DoorDash integration",
        "Provide curbside pickup and local 2-day delivery options",
        "Ship nationwide with the lowest shipping rates available",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/lp/retail-a/demo",
      },
    },
    {
      blockTitle: "Grow your business with powerful, built-in tools",
      blockList: [
        "Mailing list builder for online and in-store sales",
        "Send digital coupons, marketing, emails and Facebook campaigns",
        "Monitor your online reputation on Yelp and Google",
        "Add optional loyalty rewards to drive repeat business",
      ],
      blockImg: "retail-lp-c.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/lp/retail-a/demo",
      },
    },
  ],
};

export const retailLpTestimonials = {
  title: "What other business owners are saying",
  reviews: [
    {
      quote:
        "From the initial consultation, to ordering, to implementation and integration, the entire process of on-boarding the SpotOn POS System has been seamless and has exceeded our expectations.",
      reviewDate: "Mar 9, 2021",
      reviewer: "Pete M.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn continues to be an invaluable technology partner that listens well and proactively presents innovative solutions to drive our business forward.",
      reviewDate: "Feb 9, 2021",
      reviewer: "Ben P.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn has given me the data access I need to manage my business from a macro as well as micro perspective. The software is easy to access and utilize.",
      reviewDate: "Dec 5, 2020",
      reviewer: "Pamela H.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "Every issue and question has been answered or addressed within hours, if not minutes.I can't say enough good about this company and software.",
      reviewDate: "Feb 24, 2021",
      reviewer: "Brandy R.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "From back office, digital marketing, built our website to take orders off of. The programming is great to allow you to do more tasks with better trained staff.",
      reviewDate: "Dec 1, 2020",
      reviewer: "Marcus O.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
  ],
};

export const retailLpCTA = {
  title: "Book your consultation.",
  ctaImg: "cta-people.png",
  ctaInfo: {
    ctaTitle: "Learn more",
    ctaTarget: "/lp/retail-a/demo",
  },
};
